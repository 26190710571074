import axios from 'axios';
import Swiper from 'swiper';
import MoveTo from 'moveto';
import 'particles.js';
import svg4everybody from 'svg4everybody';
import ScrollMagic from 'scrollmagic';
import page from 'page';
import UAParser from 'ua-parser-js';
import './modernizr';

const screenWidth = window.innerWidth;

const enableParticles = () => {
  particlesJS('particles', {
    'particles': {
      'number': {
        'value': 150,
        'density': {
          'enable': true,
          'value_area': 800
        }
      },
      'color': {
        'value': '#fff'
      },
      'shape': {
        'type': 'circle',
        'polygon': {
          'nb_sides': 5
        },
      },
      'opacity': {
        'value': 0.2,
        'random': false,
        'anim': {
          'enable': false,
          'speed': 1,
          'opacity_min': 0.1,
          'sync': false
        }
      },
      'size': {
        'value': 3,
        'random': true,
        'anim': {
          'enable': false,
          'speed': 40,
          'size_min': 0.1,
          'sync': false
        }
      },
      'line_linked': {
        'enable': true,
        'distance': 80,
        'color': '#ffffff',
        'opacity': 0.4,
        'width': 1
      },
      'move': {
        'enable': true,
        'speed': 2,
        'direction': 'none',
        'random': false,
        'straight': false,
        'out_mode': 'out',
        'bounce': false,
      }
    },
    'interactivity': {
      'detect_on': 'canvas',
      'events': {
        'onhover': {
          'enable': false,
        },
        'onclick': {
          'enable': false,
        },
        'resize': true
      },
    },
    'retina_detect': true
    }
  );

  // 画像の高さによってparticlesをレンダリングする領域が決まるので画像の読み込みが遅いと崩れてしまう
  // 画像の読み込みが終わったらresizeイベントを発火して崩れを修正する
  document.querySelectorAll('.mv_image').forEach(picture => {
    picture.querySelector('img').addEventListener('load', () => {
      if (window.getComputedStyle(picture).display !== 'none') {
        window.dispatchEvent(new Event('resize'));
      }
    }, {once: true});
  });
};

const topScrollmagic = () => {
  const controller = new ScrollMagic.Controller();
  const sections = document.getElementsByTagName('section');
  const sectionArray = Array.from(sections);
  sectionArray.forEach (section => {
    const sectionTitle = section.querySelector('.section_lead');
    if (!sectionTitle) {
          return;
    }
    new ScrollMagic.Scene({
      triggerElement: section,
      offset: -100,
      reverse: false
    })
    .setClassToggle(sectionTitle,'is-lead-visible')
    .addTo(controller);
  });

  new ScrollMagic.Scene({
    triggerElement: '.infographic_list',
    offset: -100,
    reverse: false
  })
  .setClassToggle('.infographic_item_result','is-result-visible')
  .addTo(controller);

  // delay
  const elementList = document.querySelectorAll('.infographic_item_result');
  elementList.forEach((e,i) => {
    if (i >= 1) {
      e.style.transitionDelay = `${0.2*i}s`;
    }
  });
  console.log('Top Scroll Enabled');
}

const pallaxInfographic = () => {
  const controller = new ScrollMagic.Controller();
  const trigger = document.getElementsByClassName('page_infographic_item');
  const triggerArray = Array.from(trigger);
  triggerArray.forEach (element => {
    const target = element.querySelector('.infographic_item_result');
    if (!target) {
      return;
    }
    new ScrollMagic.Scene({
      triggerElement: element,
      offset: 0,
      reverse: false
    })
    .setClassToggle(target, 'is-result-visible')
    .addTo(controller);
  });
  console.log('Parallax Enabled');
}

let swiperInstance;
const initNewsSwiper = () => {
  swiperInstance = new Swiper ('.news_slider', {
    loop: true,
    wrapperClass: 'news_list:not(.is-news-hidden)',
    slideClass: 'news_item',
    pagination: {
      el: '.pagination-simple',
      type: 'bullets',
      clickable: true,
    },
  });
  console.log('Swiper set');
};

const newsPageStatus = {
  mainTag: 'all',
  subTag: 'all',
};
const enableSubTagSwitch = (rebuildCallback) => {
  const pcTagElem = document.querySelector('div.pc p.news_tag');
  const categoryList = document.querySelector('div.pc ul.news_tag_list');

  const pcListToggle = e => {
    console.log('PC Tag Toggled.');
    e.stopPropagation();
    if (!categoryList.getAttribute('style')) {
      categoryList.style.display = 'flex';
      return;
    }
    categoryList.removeAttribute('style');
  }

  const closeCategoryList = () => {
    if (categoryList.getAttribute('style')) {
      categoryList.removeAttribute('style');
    }
  };

  pcTagElem.addEventListener('click', pcListToggle);
  document.body.addEventListener('click', () => {
    console.log('Body Clicked (To Close Tag Selector)');
    closeCategoryList();
  });

  const subTagClicked = e => {
    e.stopPropagation();
    const clickedElem = e.currentTarget;
    if (clickedElem.classList.contains('is-category-active')) {
      // Close Window and Do nothing.
      closeCategoryList();
      return;
    }
    categoryList.querySelector('li.news_tag_item.is-category-active').classList.remove('is-category-active');
    clickedElem.classList.add('is-category-active');
    pcTagElem.innerText = clickedElem.innerText;

    newsPageStatus.subTag = clickedElem.getAttribute('data-sub-tag');

    if (typeof(rebuildCallback) === 'function') {
      // Rebuild Every Thing
      rebuildCallback();
    }
    closeCategoryList();
  };
  categoryList.querySelectorAll('li.news_tag_item').forEach(tagElem => {
    tagElem.addEventListener('click', subTagClicked);
  });
};

const tabletViewForTopPage = () => {
  // iPadのPCレイアウトのみ除外
  if (new UAParser().getDevice().type === 'tablet') {
    document.querySelectorAll('.service_head, .recruit').forEach(element => {
      element.style.backgroundAttachment = 'initial';
    });
  }
};

page('/*', (ctx, next) => {
  console.log('Front JS all.');

  // Direct Library Call
  svg4everybody();

  // Smooth Scrolling
  const smooth = () => {
    const headerHeight = (screenWidth > 768) ? 140 : 70;
    const moveTo = new MoveTo({
      tolerance: headerHeight
    });
    const scrollTrigger = document.querySelector('.scroller a');
    moveTo.registerTrigger(scrollTrigger);
  };
  smooth();

  // SP Only
  if (screenWidth <= 768) {
    const spMenu = () => {
      const ham = document.querySelector('.hamburger');
      const navi = document.querySelector('.header_navi');
      ham.addEventListener('click' , () => {
        navi.classList.remove('is-menu-opened');

        const hamClass = ham.classList;
        hamClass.toggle('is-opened');
        if (hamClass.contains('is-opened')) {
          navi.classList.add('is-menu-opened');
        }
      });
    };
    console.log('Init SP Mode');
    spMenu();

    console.log('Init Swiper');
    initNewsSwiper();

    document.querySelector('.header_logo').classList.remove('header_logo-color');
    next();
    return;
  }

  // Fixed Header during Non-SP Mode
  const fixedHeader = () => {
    const header = document.querySelector('.l-header');
    const logo = document.querySelector('.header_logo');
    const targetPage = document.querySelector('.page_top') || document.querySelector('.page_message');
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > window.innerHeight / 3) {
        header.classList.add('is-header-fixed');
        if (targetPage) {
          logo.classList.add('header_logo-color');
        }
        return;
      }

      header.classList.remove('is-header-fixed');
      if (targetPage) {
        logo.classList.remove('header_logo-color');
      }
    });
  }
  fixedHeader();

  if (new UAParser().getBrowser().name === 'IE') {
    document.body.style.overflow = 'hidden';
    const ieElement = document.createElement('div');
    ieElement.classList.add('ie');
    ieElement.innerHTML = `<div class="ie_text">
      <p class="ie_caution">お使いのブラウザは非推奨です</p>
      <p>推奨ブラウザは下記リンクからダウンロードできます。</p>
      <ul>
        <li>・最新版のGoogle Chrome<a href="https://www.google.com/chrome/" class="recommend_browser">https://www.google.com/chrome/</a></li>
        <li>・最新版のMozilla Firefox<a href="https://www.mozilla.org/ja/firefox/new/" class="recommend_browser">https://www.mozilla.org/ja/firefox/new/</a></li>
      </ul>
    </div>`;
    document.body.appendChild(ieElement);
  }

  next();
});

page('/', (ctx, next) => {
  console.log('Top Page.');

  topScrollmagic();
  enableParticles();
  tabletViewForTopPage();

  const allNaviButtons = document.querySelectorAll('ul.news_navi_list li.news_navi_item')

  const switchNews = e => {
    const idName = e.currentTarget.getAttribute('data-id');
    console.log(idName);

    allNaviButtons.forEach(elem => {
      elem.classList.remove('is-tab-active');
    });
    e.currentTarget.classList.add('is-tab-active');

    document.querySelectorAll('ul.news_list.swiper-wrapper').forEach(elem => {
      elem.classList.add('is-news-hidden');
    });
    document.querySelector('ul#' + idName + '.news_list.swiper-wrapper').classList.remove('is-news-hidden');

    if (swiperInstance) {
      console.log('Swiper Update');
      swiperInstance.destroy();
      initNewsSwiper();
    }
  };

  allNaviButtons.forEach(elem => {
    elem.addEventListener('click', switchNews);
  });
});

page('/company/greetings', (ctx, next) => {
  enableParticles();
});

page('/company/infographic', (ctx, next) => {
  pallaxInfographic();
});

page('/news', (ctx, next) => {
  console.log('NEWS.');

  let rawData = {};
  const useData = [];

  let paging = 0;
  const pageSize = 10;
  let totalPageLink = 1;

  const allLi = [];
  const pressListElem = document.querySelector('ul.press_list');

  const pagerItem = [];
  const newsPager = document.querySelector('ul.news_pager');

  const initUseData = () => {
    useData.length = 0; // Clear Array
    rawData.forEach(record => {
      if (record.main_tag.trim() === '') {
        record.main_tag = 'notice';
      }

      if (newsPageStatus.mainTag !== 'all' && record.main_tag !== newsPageStatus.mainTag) {
        return;
      }
      if (newsPageStatus.subTag !== 'all' && record.sub_tag !== newsPageStatus.subTag) {
        return;
      }
      useData.push(record);
    });
    totalPageLink = Math.ceil(useData.length / pageSize)
    paging = 0;
    // console.log(useData);
    console.log('Data Reconstructed.');
  };

  const buildPressList = () => {
    allLi.length = 0;
    if (useData.length === 0) {
      // No Data
      pressListElem.innerHTML = '<li class="press_item press_item-empty">お探しのニュースは更新されていません</li>';
      return;
    }

    useData.slice(paging * pageSize, (paging + 1) * pageSize).forEach(record => {
      let aLink = '<a href="/news/' + record.post_id + '" class="press_item_inner">';
      if (record.main_tag === 'pressrelease') {
        aLink = '<a href="' + record.pdf_link + '" class="press_item_inner" target="_blank">';
      }

      let liElem = '<li class="press_item">';
      liElem += aLink;
      liElem += '<div class="news_item_head">';
      liElem += '<div class="news_category_list">';
      liElem += '<span class="news_category news_category-' + record.main_tag + '">' + record.main_tag_name + '</span>';
      if (record.sub_tag_name) {
        liElem += '<span class="news_category news_category-service">' + record.sub_tag_name + '</span>';
      }
      liElem += '</div>';
      liElem += '<p class="press_item_date">' + record.published_date + '</p>';
      liElem += '</div>';
      liElem += '<p class="press_item_text">' + record.title + '</p>';
      liElem += '</a>';
      liElem += '</li>';

      allLi.push(liElem);
    });

    pressListElem.innerHTML = '';
    pressListElem.innerHTML = allLi.join('');
    console.log('PressList ReBuilded.');
  };

  const pagerClickEvent = (e) => {
    e.preventDefault();
    const clickedElem = e.currentTarget;

    const switchPagerActive = (newPagingValue) => {
      const currentPageElement = document.querySelector('a[data-paging="' + paging + '"]');
      if (currentPageElement) {
        currentPageElement.classList.remove('is-pager-active');
      }

      paging = newPagingValue;

      const nextPageElement = document.querySelector('a[data-paging="' + paging + '"]');
      if (nextPageElement) {
        nextPageElement.classList.add('is-pager-active');
      }

      // Rebuild PressList
      buildPressList();
      buildPager();

      // Scroll Up
      document.querySelector('nav.news_navi').scrollIntoView(true);
    };

    // Previous
    if (clickedElem.classList.contains('news_pager_link-prev')) {
      console.log('Prev Pressed.');
      if (paging - 1 < 0) {
        return;
      }
      switchPagerActive(paging - 1);
      return;
    }

    // Next
    if (clickedElem.classList.contains('news_pager_link-next')) {
      console.log('Next Pressed.');
      if (paging + 1 >= totalPageLink) {
        return;
      }
      switchPagerActive(paging + 1);
      return;
    }

    // Others
    const thisPagingValue = parseInt(clickedElem.getAttribute('data-paging'), 10);
    console.log(thisPagingValue + ' Pressed.');
    switchPagerActive(thisPagingValue);
  };

  const buildPager = () => {
    pagerItem.splice(0);
    // Remove Event
    newsPager.querySelectorAll('li.news_pager_item a.news_pager_link').forEach(oldLink => {
      oldLink.removeEventListener('click', pagerClickEvent);
    });

    if (useData.length === 0) {
      // No Data
      newsPager.innerHTML = '';
      return;
    }

    pagerItem.push('<li class="news_pager_item"><a class="news_pager_link news_pager_link-prev"></a></li>');

    const pageLinkElem = (index, className) => '<li class="news_pager_item ' + (className ? className : '') + '"><a data-paging="' + index + '" class="news_pager_link ' + (index == paging ? 'is-pager-active' : '') + '">' + (index + 1) + '</a></li>';
    const ellipisis = '<li class="news_pager_item news_pager_ellipsis"><span>...</span></li>';

    // 7ページ以上
    if (totalPageLink > 6) {
      if (paging < 3) {
        console.log('First 3 Page')
        for (let i = 0; i < 5; i++) {
          pagerItem.push(pageLinkElem(i, 'is-pager-forward'));
        }

        pagerItem.push(ellipisis);
        pagerItem.push(pageLinkElem(totalPageLink - 1));
      } else if (paging > totalPageLink - 4) {
        console.log('Last 3 Page')
        pagerItem.push(pageLinkElem(0));
        pagerItem.push(ellipisis);

        for (let i = totalPageLink - 5; i < totalPageLink; i++) {
          pagerItem.push(pageLinkElem(i, 'is-pager-backward'));
        }
      } else {
        pagerItem.push(pageLinkElem(0));
        if (paging !== 3 || screenWidth <= 768) {
          pagerItem.push(ellipisis);
        }
        for (let i = paging - 2; i < paging + 3; i++) {
          let className = '';
          if (i === paging - 2) {
            className = 'is-pager-active-before';
          }
          if (i === paging + 2) {
            className = 'is-pager-active-after';
          }
          pagerItem.push(pageLinkElem(i, className));
        }
        if (paging !== totalPageLink - 4 || screenWidth <= 768) {
          pagerItem.push(ellipisis);
        }
        pagerItem.push(pageLinkElem(totalPageLink - 1));
      }

    } else {
      // 5ページ以下
      // Page Number
      for (let i = 0; i < totalPageLink; i++) {
        pagerItem.push(pageLinkElem(i));
      }
    }

    pagerItem.push('<li class="news_pager_item"><a class="news_pager_link news_pager_link-next"></a></li>');
    newsPager.innerHTML = '';
    newsPager.innerHTML = pagerItem.join('');

    // Append Event
    newsPager.querySelectorAll('li.news_pager_item a.news_pager_link').forEach(newLink => {
      newLink.addEventListener('click', pagerClickEvent);
    });
    console.log('Pager ReBuilded.');
  };

  const rebuildContent = () => {
    initUseData();
    buildPressList();
    buildPager();
  };

  // Main Tag Selector
  const mainTagClickEvent = (e) => {
    const clickedElem = e.currentTarget;
    // Clicked on Active tab
    if (clickedElem.classList.contains('is-tab-active')) {
      return;
    }

    document.querySelector('li.news_navi_item.is-tab-active').classList.remove('is-tab-active');
    clickedElem.classList.add('is-tab-active');
    newsPageStatus.mainTag = clickedElem.getAttribute('data-main-tag');

    // Rebuild Every Thing
    rebuildContent();
  };

  document.querySelectorAll('li[data-main-tag].news_navi_item').forEach(mainTagElem => {
    mainTagElem.addEventListener('click', mainTagClickEvent);
  });

  // Sub Tag Selector (PC)
  enableSubTagSwitch(rebuildContent);

  // Sub Tag Selector (SP)
  const spSubTagChanged = e => {
    const selectElem = e.currentTarget;
    document.querySelector('div.pc li.news_tag_item.is-category-active').classList.remove('is-category-active');
    document.querySelector('div.pc li[data-sub-tag="' + selectElem.value + '"].news_tag_item').classList.add('is-category-active');
    document.querySelector('div.pc p.news_tag').innerText = selectElem.options[selectElem.selectedIndex].text;
    newsPageStatus.subTag = selectElem.value;

    rebuildContent();
  };
  document.querySelector('div.sp select').addEventListener('change', spSubTagChanged);

  // News Contents Init
  axios.get('/newslist')
  .then(res => {
    rawData = res.data.allArticles;
    rebuildContent();
  })
  .catch(err => {
    alert('エラーが発生しました。時間がしばらく経ってからお試しください。');
    throw new Error(err);
  });
});

page.start({
  click: false,
  popstate: false
});
